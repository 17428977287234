import React from "react";
import ReactDOM from "react-dom";
import SwaggerUI from "swagger-ui-react";
// import "swagger-ui-react/swagger-ui.css";
import "./styles.css";


import "swagger-ui-react/swagger-ui.css";
function App() {
  return (
    <div className="App">
      <SwaggerUI url="https://nspdfjs.s3.amazonaws.com/swaggerapi_Irvine1.json"/>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
